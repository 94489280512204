
.notification-top{
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 95% !important;
    align-items: center;
}



.top{
    width: 100%;
    /* max-width: 250px; */

    /* background-color:cadetblue; */
    /* background-color:#10336F; */
    /* background-color:#e62128; */
    background-color:var(--primary-color);
    
    /* border: solid; */
    /* border-width: unset; */

    height: 3rem;

}


.logo-img{
    max-width: 250px;
    padding-left: 1rem;
}


/* .sidebar .nav {

    background-color: aqua;
    display: block;

} */

.hora{
    width: auto !important;
    color: white;
}

@media (max-width: 768px) {
    .hora {
        display: none;
    }
}