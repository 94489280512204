.table-fav {
  width: auto;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin-top: 20px;
}

.table-fav th,
.table-fav td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

.table-fav th.sorted {
  background-color: lightblue;
}

.table-fav th.sorted::after {
  content: "";
  display: inline-block;
  margin-left: 5px;
}

.pagination-fav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-fav .items-per-page {
  display: flex;
  align-items: center;
}

.pagination-fav .items-per-page label {
  margin-right: 5px;
}

.pagination-fav .pagination-numbers span {
  margin-right: 5px;
  cursor: pointer;
  padding: 5px;
}

.pagination-fav .pagination-numbers span.active {
  font-weight: bold;
  text-decoration: underline;
}



.table-container-fav {
  overflow-x: auto;
  width: 160vh;

  @media screen and (max-width :1024px) {
    width: 100vh;
  }
}



.table-responsive-fav {
  width: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  border-collapse: collapse;
}
