.custom-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

.custom-table thead {
    background-color: #f8f9fa; /* Fondo claro para la cabecera */
}

.custom-table th, .custom-table td {
    border: 1px solid #dee2e6;
    padding: 8px;
    text-align: left;
}

.custom-table th {
    background-color: #e9ecef; /* Fondo claro para las celdas de la cabecera */
    color: #495057; /* Color del texto de la cabecera */
}

.custom-table tr:nth-child(even) {
    background-color: #f2f2f2; /* Fondo alternado para las filas */
}

.custom-table tr:hover {
    background-color: #f1f1f1; /* Fondo para el hover de las filas */
}

.custom-table td {
    background-color: #ffffff; /* Fondo claro para las celdas */
    color: #212529; /* Color del texto */
}
