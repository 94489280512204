.menu{
    text-align: center;
    justify-content: center;
    margin-top: 10vh;
    min-width: 35%;
}

/* .login-wrapper{
    display: flex;
    justify-content: center;
} */

.wrapper{
    background-color: var(--primary-background-color);
    min-height: 95vh;
}

.sidebar-menu.show {
    display: block;
}

.sidebar-menu {
    background-color: white;
}

.main-panel{
    margin: 3vh;
    background-color: var(--secondary-background-color);
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.main-header{
    display: none;
}

@media (max-width: 768px) {
    .main-panel {
        margin: 3vh 0 0 0;
    }

    .wrapper{
        display: block;
    }

    .main-page{
        display: flex;
        flex-direction: column;
    }

    .main-header{
        background-color: white;
        width: 100%;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2rem;
    }

    .main-header-logo{
        max-height: 3rem; 
        width: auto;
    }

    .sidebar-menu {
        position: absolute;
        top: 7rem;
        right: 1.5rem;
        width: 300px;
        background-color: white;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: none;
        border-radius: 4px;
    }
}

/*.main-panel{
    background-color: chocolate;
    height: auto;
    min-height: 90vh;
  }

  */