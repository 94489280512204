.modal-containerr {
  display: table;
  width: 100%;
  height: 100%;
  background-color: black;
}

.modal-backdropp {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.5);
}

.modall {
  display: flex;
  /* flex-direction: column;
  justify-content: space-between;
  align-items: center; */
  width: 50%;
  height: 100%;
  background-color: white;
  /* padding: 1rem; */
  animation-name: slide-in;
  animation-duration: 0.5s;
}

@media (max-width: 768px){
  .modall {
    width: 80%;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
