/* Estilos CSS */
/* Añade estos estilos en un archivo .css o en tu archivo de estilos */

/* Estilos para el contenedor principal */
.Main {
  font-family: Arial, sans-serif;
  padding: 20px;
}

/* Estilos para el título */
.ContenedorTitulo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Estilo para la tabla de dispositivos */
.TablaDispositivos {
  display: flex;
  flex-direction: column;
}

/* Estilos para los TextField */
.TextFieldCustom {
  margin-bottom: 10px;
  width: 300px;
  /* Ancho personalizado, ajusta según tus necesidades */
}

/* Estilos para los botones */
.ButtonCustom {
  margin-top: 10px;
  /* Espacio superior */
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

/* Estilo para el botón de eliminar */
.ButtonCustomEliminar {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ff0000;
  /* Color de fondo rojo */
  color: #ffffff;
  /* Color de texto blanco */
}