body {
  font-family: Arial, Helvetica, sans-serif;
}

[draggable="true"] {
  cursor: move;
}

.container_3 {
  width: auto;
  margin: 2px;
  border: 2px solid black;
}

.container_2 {
  width: auto;
  border: 2px solid blue;
}

.widgetBox {
  background-color: #e5eaea;
  display: inline-block;
  color: black;
  width: 315px;
  height: 400px;
  /* padding: 5rem; */
  resize: both;
  overflow: auto;
  min-width: 325px;
  min-height: 370px;
  size: cover;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 15px;
  object-fit: inherit;
  position: relative;
}

img {
  width: 100%;
}

h1.titulos {
  text-align: center;
}

#mydiv {
  position: absolute;
  z-index: 9;
  background-color: #f1f1f1;
  text-align: center;
  border: 1px solid #d3d3d3;
}

#mydivheader {
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #2196f3;
  color: #fff;
}

.dashboard-modal-table {
  display: grid;
  grid-template-columns: 1fr; /* Asegura que las columnas se adapten al tamaño del contenedor */
  overflow: auto;
}