/* RadialBarTemp.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,500&family=Poppins&display=swap');
.image-container {
  /* La imagen no se ampliará más allá de su tamaño original */
  max-width: 100%;
  height: 100%;
  /* La imagen no se ampliará más allá de su tamaño original */
  max-height: 100%;
  align-items: center;

}

.widgets-font {
  /* font-family: 'Pixelify Sans', cursive; */
  /* font-family: 'Source Code Pro', monospace; */
  font-family: 'Poppins', cursive;
  /* font-family: 'Montserrat',italic; */


}