.table {
  width: 100%;
  table-layout: inherit;
  border-collapse: collapse;
}

.table-container-overflow {
  overflow: auto;
  width: 100%;
  height: 100%;
}

.table th,
.table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

.table th.sorted {
  background-color: lightblue;
}

.table th.sorted::after {
  content: "";
  display: inline-block;
  margin-left: 5px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination .items-per-page {
  display: flex;
  align-items: center;
}

.pagination .items-per-page label {
  margin-right: 5px;
}

.pagination .pagination-numbers span {
  margin-right: 5px;
  cursor: pointer;
  padding: 5px;
}

.pagination .pagination-numbers span.active {
  font-weight: bold;
  text-decoration: underline;
}
